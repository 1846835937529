import React from "react";

const About = () => {
  return (
    <div name="about" className="w-full h-screen bg-black text-gray-300">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pl-4">
            <p className="text-4xl font-bold inline border-b-4 border-yellow-400">
              About
            </p>
          </div>
          <div></div>
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
          <div className="sm:text-right text-4xl font-bold ">
            <p>Hi. I'm Fredrik, nice to meet you. Please take a look around.</p>
          </div>
          <div>
            <p className="text-lg">
              I am a recent graduate developer who loves everything related to
              web development, from creating new websites to enhancing existing
              ones. My interest in the field sparked three years ago and two years ago i began
              my studies in Web Development.NET. Since then, I have continued to
              evolve both through my education and independently in my free
              time. My goal is to become a Fullstack, Frontend, or an
              Application developer. I am also currently seeking my first job as
              a developer.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
