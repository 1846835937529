import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";

const Home = () => {
  return (
    <div name="home" className="w-full h-screen bg-black">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <p className="text-white text-lg">Hi, my name is</p>
        <h1 className="text-4xl sm:text-7xl font-bold text-yellow-400 ">
          Fredrik Nordberg
        </h1>
        <h2 className="text-4xl sm:text-7xl font-bold text-white">
          I'm a Junior Full Stack Developer
        </h2>
        <p className="text-white text-lg py-4 max-w-[]700px">
          Hi, I'm Fredrik Nordberg, a Junior Fullstack Developer with a special
          interest in Front-end. I have recently graduated in Web
          Development.NET and am now seeking a job in this field. I really enjoy
          working on the front-end part of websites and apps. Making things look
          good and easy to use is what I love doing most. Whether it's making a
          website look great or making sure everything works smoothly behind the
          scenes, I enjoy every bit of it.
        </p>
        <div>
          <button className="text-white border-2 px-6 -py-3 my-2 flex items-center hover:border-yellow-400">
            <Link className="text-lg" to="work" smooth={true} duration={500}>
              View Work!
            </Link>{" "}
            <HiArrowNarrowRight className="ml-3" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
