import React from "react";
import TravelCamp from "../assets/TravelCamp.png";
import SerieZone from "../assets/seriezone.png"
import BookApp from "../assets/bookapp.png"
import BeautySalon from "../assets/beautysalon.png"

const Work = () => {
  return (
    <div name="work" className="w-full md:h-screen text-white bg-black">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 text-white border-yellow-400">
            Work
          </p>
          <p className="py-6 text-lg">Check out some of my recent work</p>
        </div>

        {/* CONTAINER */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-5">
          {/* GRID ITEM 1 */}
          <div
            style={{ backgroundImage: `url(${TravelCamp})` }}
            className="shadow-lg shadow-slate-500 group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* HOVER EFFECTS */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-lg font-bold text-white tracking-wider">
              Travel UI/UX Website | React.js, Next.js, TypeScript, Tailwind CSS
              </span>
              <div className="pt-8 text-center">
                {/* <a href="/">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    Demo
                  </button>
                </a> */}
                <a href="https://github.com/FredrikNordberg/camp-travel-react">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>

          {/* GRID ITEM 2 */}
          <div
            style={{ backgroundImage: `url(${SerieZone})` }}
            className="shadow-lg shadow-slate-500 group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* HOVER EFFECTS */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-lg font-bold text-white tracking-wider">
                Netflix Clone | HTML, CSS, JavaScript.
              </span>
              <div className="pt-8 text-center">
                {/* <a href="/">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    Demo
                  </button>
                </a> */}
                <a href="https://github.com/FredrikNordberg/seriezone-series-api">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          {/* GRID ITEM 3 */}
          <div
            style={{ backgroundImage: `url(${BookApp})` }}
            className="shadow-lg shadow-slate-500 group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* HOVER EFFECTS */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-lg font-bold text-white tracking-wider">
                Google Book API | React.js, CSS, HTML
              </span>
              <div className="pt-8 text-center">
                {/* <a href="/">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    Demo
                  </button>
                </a> */}
                <a href="https://github.com/FredrikNordberg/book-api">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>

          {/* GRID ITEM 4 */}
          <div
            style={{ backgroundImage: `url(${BeautySalon})` }}
            className="shadow-lg shadow-slate-500 group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* HOVER EFFECTS */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-lg  font-bold text-white tracking-wider">
              Beauty Salon UI/UX Website | React.js, Next.js, TypeScript, Tailwind CSS
              </span>
              <div className="pt-8 text-center">
                {/* <a href="/">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    Demo
                  </button>
                </a> */}
                <a href="https://github.com/FredrikNordberg/homepage-beauty-salon">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>

          {/* Repeat GRID ITEM N as needed */}
        </div>
      </div>
    </div>
  );
};

export default Work;
