import React from 'react'

import HTML from '../assets/html.png'
import CSS from '../assets/css.png'
import JavaScript from '../assets/javascript.png'
import ReactImg from '../assets/react.png'
import TypeScript from '../assets/ts.png'
import Github from '../assets/github.png'
import Csharp from '../assets/c.png'
import Tailwind from '../assets/tailwind.png'

const Skills = () => {
  return (
    <div name="skills" className='w-full h-screen bg-black text-white'>
        {/* Container */}
        <div className='max-w-[1000px] p-4 mx-auto flex flex-col justify-center w-full h-full'>
            <div>
                <p className='text-4xl font-bold inline border-b-4 border-yellow-400 '>Skills</p>
                <p className='py-4'>These are some of the technologies I've worked with</p>
            </div>

            <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
                <div className='shadow-md shadow-yellow-400 hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={HTML} alt="HTML icon" />
                    <p className='my-4'>HTML</p>
                </div>
                <div className='shadow-md shadow-yellow-400 hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={CSS} alt="HTML icon" />
                    <p className='my-4'>CSS</p>
                </div>
                <div className='shadow-md shadow-yellow-400 hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={JavaScript} alt="HTML icon" />
                    <p className='my-4'>JavaScript</p>
                </div>
                <div className='shadow-md shadow-yellow-400 hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={ReactImg} alt="HTML icon" />
                    <p className='my-4'>ReactJS</p>
                </div>
                <div className='shadow-md shadow-yellow-400 hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={TypeScript} alt="HTML icon" />
                    <p className='my-4'>TypeScript</p>
                </div>
                <div className='shadow-md shadow-yellow-400 hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={Github} alt="HTML icon" />
                    <p className='my-4'>Github</p>
                </div>
                <div className='shadow-md shadow-yellow-400 hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={Csharp} alt="HTML icon" />
                    <p className='my-4'>C#</p>
                </div>
                <div className='shadow-md shadow-yellow-400 hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={Tailwind} alt="HTML icon" />
                    <p className='my-4'>Tailwind</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Skills
